import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { ModalPropsType } from './types'
// import { useTransition, animated, useSpring } from '@react-spring/web'
import { motion, AnimatePresence } from "framer-motion"
//styles
import styles from './modal.module.scss'
import classNames from 'classnames';
import { DeviceType, identifyDevice } from '@/helper/identifyDevice';

const Modal = ({ show, children, onClose }: ModalPropsType) => {
    const [open, setOpen] = useState<boolean>(show);

    useEffect(() => {
        setOpen(show);
    }, [show])

    const close = useCallback(() => {
        setOpen(false);
        setTimeout(() => {
            onClose();
        }, 300);
    }, [onClose]);

    const dropIn = useMemo(() => {
        return {
            hidden: {
                y: '100vh',
                opacity: 0
            },
            visible: {
                y: '0',
                opacity: 1,
                transition: {
                    duration: 0.3,
                    type: "tween",
                },
            },
            exit: {
                y: '100vh',
                opacity: 0,
                transition: {
                    duration: 0.3,
                    type: "tween",
                },
            }
        }
    }, [])

    return (
        <AnimatePresence
            initial={false}
            exitBeforeEnter={true}>
            {open && (<motion.div
                className={classNames(styles.container)}>
                <motion.div
                    initial={{ opacity: 0, }}
                    animate={{ opacity: 1, }}
                    exit={{ opacity: 0 }}
                    onClick={onClose}
                    className={classNames(styles.overlay_container)} />
                <motion.div
                    variants={dropIn}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    className={classNames(styles.subContainer)}>
                    {children}
                </motion.div>
            </motion.div>)}
        </AnimatePresence>
    )
}

export default Modal